<template>
  <div class="box px-3 py-3" style="min-width: 300px; max-width: 500px">
    <b-field class="mb-0">
      <b-dropdown
          :scrollable="false"
          v-model="currentTool"
          aria-role="list"
          position="is-top-right"
      >
        <template #trigger>
          <b-button
              :label="currentTool.text"
              type="is-white"
              :icon-left="currentTool.icon"
              icon-right="menu-down" />
        </template>

        <b-dropdown-item v-for="(menu, index) in tools"
                         :key="index"
                         :value="menu"
                         aria-role="listitem"
                         @click="changeTool">
          <div class="media">
            <b-icon class="media-left" :icon="menu.icon"></b-icon>
            <div class="media-content">
              <h3>{{menu.text}}</h3>
            </div>
          </div>
        </b-dropdown-item>
      </b-dropdown>
      <p class="control">
        <treeselect v-if="currentTool.mode === 'draw_point'"
                    v-model="currentCategory"
                    :multiple="false"
                    :show-count="true"
                    @input="changeToolCategory"
                    :options="categories" />

        <b-button @click="confirmDelete" v-if="selected.length > 0" type="is-white" icon-left="delete">Remove</b-button>
        <template v-if="selected.length === 1 && selected[0].geometry">
          <b-button @click="addChild" v-if="selected[0].geometry.type === 'Point'" type="is-white" icon-left="plus-circle-multiple-outline">Add child</b-button>
<!--          <b-button @click="directDragMode" v-if="selected[0].geometry.type === 'LineString'" type="is-white" icon-left="vector-line">Edit</b-button>-->
          <b-button @click="resetConnection" v-if="selected[0].geometry.type === 'LineString'" type="is-white" icon-left="vector-line">Reset</b-button>
        </template>

      </p>

    </b-field>

  </div>

</template>

<script>
import Treeselect from '@riophae/vue-treeselect'
import '@riophae/vue-treeselect/dist/vue-treeselect.css'

export default {
  name: "Toolbar",
  components: { Treeselect },
  data() {
    return {
      currentCategory: null,
      currentTool: { icon: 'select', text: 'Select', mode: 'simple_select' },
      tools: [ { icon: 'server-plus', text: 'New Item', mode: 'draw_point' },
        { icon: 'shape-polygon-plus', text: 'New Zone', mode: 'draw_polygon' },
        { icon: 'select', text: 'Select', mode: 'simple_select' },

      ]
    }
  },
  computed: {
    categories() {
      return this.$store.state.deplo.categories
    },
    mode() {
      return this.$store.state.deplo.currentTool
    },
    selected() {
      return this.$store.state.deplo.selectedFeatures
    }
  },
  watch: {
    mode(nieuw, old) {
      if(nieuw !== this.currentTool.mode) {
        // TODO: Add different modes
        this.currentTool = { icon: 'select', text: 'Select', mode: 'simple_select' }
      }
    }
  },
  methods: {
    confirmDelete() {
      this.$buefy.dialog.confirm({
        title: 'Deleting items',
        message: 'Are you sure you want to <b>delete</b> ' + this.selected.length + ' items? This cannot be undone.',
        confirmText: 'Delete ',
        type: 'is-danger',
        hasIcon: true,
        onConfirm: () => this.deleteItems(this.selected)
      })

    },
    deleteItems() {
      this.$store.dispatch('deplo/deleteItems', this.selected)
    },
    changeTool() {
      this.$store.commit("deplo/setCurrentTool", {tool: this.currentTool.mode, options: null})
    },
    changeToolCategory() {
      console.log("tool changed")
      this.$store.commit("deplo/setCurrentToolCategory", this.currentCategory)
    },
    directDragMode() {
      this.currentTool =  { icon: 'cursor-default-click', text: 'Direct Drag', mode: 'direct_select' }
      this.$store.commit("deplo/setCurrentTool",
          {tool: this.currentTool.mode, options: {featureId: this.selected[0].id}})
    },
    addChild() {
      if(!this.currentCategory) {
        this.currentCategory = this.selected[0].properties.category_id
        this.$store.commit("deplo/setCurrentToolCategory", this.currentCategory)
      }
      this.$store.commit("deplo/setWaitingForChildCreation", this.selected[0])
      this.$store.commit("deplo/setCurrentTool", {tool: 'draw_point', options: null})
    },
    resetConnection() {
      this.$store.dispatch("deplo/resetConnection", this.selected[0])
    }
  }
}
</script>

<style scoped>

</style>