<template>
  <div id="wrapper">
    <div id="over_map">
      <card-component class="has-table pb-3" style="height: 100%">
        <b-loading :active="loading" :is-full-page="false">
        </b-loading>
          <transition name="fade" :duration="200" mode="out-in">
            <router-view name="side_view"/>
          </transition>

      </card-component>
    </div>
    <div  id="toolbar">
      <toolbar></toolbar>
    </div>

    <section class="section">
      <div id="container">
        <div id='map' class='map' style="height: 100vh">
        </div>
      </div>
    </section>

  </div>

</template>

<script>
import '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css'
import "mapbox-gl-style-switcher/styles.css";
import settings from "@/store/modules/settings.store";
import projects from "@/store/modules/projects.store"
import deplo from "../store/deplo.store";
import CardComponent from '@/components/CardComponent'
import Toolbar from "@/components/deplo/components/Toolbar";

export default {
  name: "DeploHome",
  components: {
    Toolbar,
    CardComponent
  },
  data () {
    return {
      publicPath: process.env.BASE_URL,
      selected: null,
      isCreating: false,
    }
  },

  created() {
    if(!settings.isRegistered) {
      this.$store.registerModule('settings', settings)
      settings.isRegistered = true
    }
    if(!deplo.isRegistered) {
      this.$store.registerModule('deplo', deplo)
      deplo.isRegistered = true
    }
    if(!projects.isRegistered) {
      this.$store.registerModule('projects', projects)
      projects.isRegistered = true
    }
    this.$store.commit('settings/asideRightToggle', false)
    this.$store.commit('settings/navBarOnly', true)
  },

  beforeDestroy () {
    this.$store.commit('settings/fullPage', false)
  },

  beforeMount() {
    this.$store.dispatch('projects/fetchProjectDetails', {id: this.id}).then(response => {
      this.$store.commit("deplo/setCurrentProjectName", response.data.data.name)
    })
  },

  mounted() {
    this.fetchDevices()
    this.$store.dispatch('deplo/fetchMapLevels', this.id).then(response => {
      this.$store.dispatch('deplo/loadMap', this.id)
    })

  },

  computed: {
    loading() {
      return this.$store.state.deplo.sidepanelLoading
    },
    id() {
      return this.$route.params.project_id
    }
  },

  methods: {
    async fetchDevices () {

      this.$store.dispatch("deplo/fetchAllMapItems", this.id)
      this.$store.dispatch("deplo/fetchAllCategories")
      //this.$store.dispatch("deplo/fetchAllConnections", this.id)

    },

  }
}
</script>

<style scoped>

#map {
  width: 100%;
  height: 100%;
}

.section{
  height: 100vh;
  padding: 0;
  padding-top: 0;
}
#wrapper {
  position: relative;
}
#over_map {
  position: absolute;
  top: 40px;
  left: 40px;
  width: 30%;
  max-height: 90vh;
  z-index: 3; }

#toolbar {
  position: absolute;
  bottom: 40px;
  left: 40%;
  z-index: 3; }
</style>
